//SHOP GALLERY
const shopGalleryThumbs = document.querySelectorAll(
    ".hoox-shop__gallery-thumbs .swiper-slide"
  );
  
  if (shopGalleryThumbs.length > 0) {
    var galleryThumbs = new Swiper(".swiper-thumbs", {
      slidesPerView: 5,
      loop: true,
      spaceBetween: 10,
      watchSlidesProgress: true,
  
      breakpoints: {
        768: {
          spaceBetween: 40,
          direction: 'vertical',
        },
      },
    });
  
    var galleryTop = new Swiper(".hoox-shop__gallery-main", {
      slidesPerView: 1,
      loop: true,
  
      thumbs: {
        swiper: galleryThumbs,
      },
    });
  }

  // POPUP TOGGLE
const popupTriggers = document.querySelectorAll(".js-popup-toggle-button");
const popup = document.querySelector(".hoox-shop-popup");
const html = document.querySelector("html");

if (popup) {
  popupTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      popup.classList.toggle("active");
      html.classList.toggle("no-scroll");
    });
  });
}

const shopSectionAll = document.querySelectorAll('.hoox-shop');
shopSectionAll.forEach(shopSection => {
    if (shopSection) {
        const props = shopSection.querySelectorAll('.hoox-shop__whats-inside'); 
        props.forEach(ul => {
            if (ul) {
                const pdpListItems = ul.querySelectorAll('p');
                pdpListItems.forEach(li => {
                    const checkmarkSvg = `<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="0.90332" width="18" height="18" rx="9" fill="#FCEE9C"/>
                    <path d="M7.65496 13.0436L7.79755 13.1885L7.94013 13.0436L13.1426 7.75459L13.2805 7.61434L13.1426 7.47409L12.4432 6.76307L12.3006 6.61812L12.158 6.76307L7.79755 11.1961L5.84197 9.20798L5.69939 9.06302L5.5568 9.20798L4.85742 9.919L4.71946 10.0592L4.85742 10.1995L7.65496 13.0436Z" fill="#4A5761" stroke="#4A5761" stroke-width="0.4"/>
                    </svg>
                    `;

                    const checkmark = document.createElement('div');
                    checkmark.classList.add('hoox-shop__whats-inside-icon');
                    checkmark.innerHTML = checkmarkSvg;
                    li.prepend(checkmark);
                });
            }
        });
    }
})

//SHOP LOGIC

var checkoutBtns = document.querySelectorAll('.js-hoox-atc-btn');
if (!!checkoutBtns.length) {
  checkoutBtns.forEach(function(btn) {
    btn.addEventListener('click', function() {
      var parentShop = btn.closest('.hoox-shop');
      var id = btn.dataset.variantId;
      var quantity = parentShop?.querySelector('#quantity')?.value || 1;
      var url = `https://${window.Shopify.shop}/cart/${id}:${quantity}`;

      if (id) {
        window.location.assign(url); 
      }
    })
  })
}

var shopValues = document.querySelectorAll('.hoox-shop__value');

if (!!shopValues.length) {
  shopValues.forEach(function (value) {
    var parentShop = value.closest('.hoox-shop');
    var parentOption = value.closest('.hoox-shop__select-wrapper');

    // Check if the value is initially active and print SVG
    if (value.classList.contains('active')) {
      var svgElement = document.createElement('svg');
      // Configure your SVG element here
      svgElement.innerHTML = `<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.69717 9.47355L4.76846 9.54603L4.83975 9.47355L12.3313 1.85742L12.4003 1.78729L12.3313 1.71717L11.3242 0.693303L11.2529 0.620825L11.1816 0.693303L4.76846 7.21309L1.8184 4.21397L1.74711 4.14149L1.67582 4.21397L0.668699 5.23783L0.599721 5.30796L0.668699 5.37809L4.69717 9.47355Z" fill="#4A5761" stroke="#4A5761" stroke-width="0.2"/>
      </svg>`;
      value.prepend(svgElement);
    }

    value.addEventListener('click', function () {
      var elOptionName = parentOption?.querySelector('.hoox-shop__option-name span');
      var btn = parentShop.querySelector('.js-hoox-atc-btn');

      // Update option name title
      if (elOptionName) {
        elOptionName.innerHTML = value.dataset.value;
      }

      // Handle active class
      var activeValue = parentOption?.querySelector('.hoox-shop__value.active');
      if (activeValue) {
        activeValue.classList.remove('active');
        // Remove the SVG element from the previously active value
        var svgElement = activeValue.querySelector('svg');
        if (svgElement) {
          activeValue.removeChild(svgElement);
        }
      }
      value.classList.add('active');

      // Get Variant by options
      var allActiveValues = parentShop.querySelectorAll('.hoox-shop__value.active');
      var allActiveValuesIds = [...allActiveValues].map(function (v) {
        return v.dataset.value;
      });
      var chosenVariant = getProductData(value).variants.find(function (v) {
        return arraysMatch(allActiveValuesIds, v.options);
      });

      // Set btn id
      if (btn) {
        btn.dataset.variantId = chosenVariant.id;
      }

      // Update price
      priceHandler(value);

      var optionValue = value.dataset.value;

      var images = parentShop.querySelectorAll('.hoox-shop__gallery-main img');
      var optionImage = [...images].find(function (img) {
        return img.alt.includes(optionValue);
      });
      if (optionImage) {
        var imgParent = optionImage.closest('.swiper-slide');
        var imgParentData = imgParent.dataset.swiperSlideIndex;
        galleryTop.slideTo(+imgParentData + 1);
      }

      // Add an SVG element in front of the value if it is active
      if (value.classList.contains('active')) {
        var svgElement = document.createElement('svg');
        // Configure your SVG element here
        svgElement.innerHTML = `<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.69717 9.47355L4.76846 9.54603L4.83975 9.47355L12.3313 1.85742L12.4003 1.78729L12.3313 1.71717L11.3242 0.693303L11.2529 0.620825L11.1816 0.693303L4.76846 7.21309L1.8184 4.21397L1.74711 4.14149L1.67582 4.21397L0.668699 5.23783L0.599721 5.30796L0.668699 5.37809L4.69717 9.47355Z" fill="#4A5761" stroke="#4A5761" stroke-width="0.2"/>
        </svg>`;
        value.prepend(svgElement);
      }
    });
  });
}



var quantityAll = document.querySelectorAll('.hoox-shop__actions');

quantityAll.forEach(function (element) {
  var parentElement = element.closest('.hoox-shop')
  var quantityInput = parentElement.querySelector('.quantity-input__value');
  var qtyIncreaseButton = parentElement.querySelector('.quantity-input__button-increase');
  var qtyDecreaseButton = parentElement.querySelector('.quantity-input__button-decrease'); 

  qtyDecreaseButton.addEventListener('click', function () {
    updateQuantity(-1, quantityInput); 
    priceHandler(element);
  });

  qtyIncreaseButton.addEventListener('click', function () {
    updateQuantity(1, quantityInput); 
    priceHandler(element);
  });

  quantityInput.addEventListener('input', function () {
    updateQuantityOnChange(quantityInput, element); 
    priceHandler(element);
  });
});

function updateQuantity(change, quantityInput) { 
  var currentQuantity = parseInt(quantityInput.value);

  if (!isNaN(currentQuantity + change) && currentQuantity + change >= 1) {
    var newQuantity = currentQuantity + change;
    quantityInput.value = newQuantity;
  }
}

function updateQuantityOnChange(quantityInput, element) { 
  var currentQuantity = parseInt(quantityInput.value);

  if (!isNaN(currentQuantity) && currentQuantity >= 1) {
    priceHandler(element);
  }
}

function priceHandler(element) {
  var parentShop = element.closest('.hoox-shop');
  var elPrice = parentShop.querySelector('.js-hoox-atc-btn .hoox-shop__price');
  var elPriceCompare = parentShop.querySelector('.js-hoox-atc-btn .hoox-shop__price--compare');
  var variant = getCurrentVariantByButtonId(element);
  var currentQuantity = parentShop.querySelector('#quantity')?.value || 1;

  if (variant) {
    if (elPrice) {
      elPrice.innerHTML = formatCurrency((+variant.price / 100) * +currentQuantity);
    }
    if (elPriceCompare && variant.compare_at_price) {
      elPriceCompare.innerHTML = formatCurrency((+variant.compare_at_price / 100) * +currentQuantity);
    }
  }
}

function getCurrentVariantByButtonId(element) {
  var parent = element.closest('.hoox-shop');
  var btnId = parent.querySelector('.js-hoox-atc-btn')?.dataset.variantId;
  var productData = getProductData(element);
  var variant = productData.variants.find(function(v) {
    return +btnId === +v.id;
  });

  return variant;
}

function getProductData(element) {
  var parent = element.closest('.hoox-shop');
  var productJSON = parent?.querySelector('.hoox-shop__product-json')?.textContent;

  if (productJSON) {
    return JSON.parse(productJSON);
  }
}

function arraysMatch(arr1, arr2) {
  return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
}

function formatCurrency(amount) {
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: window?.Shopify?.currency?.active || 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
  });
  return formatter.format(amount);
}

// gallery lp2 replace on mob

var galleryLp2 = document.querySelector('.hoox-shop__gallery-lp-2');
var galleryMain = document.querySelector('.hoox-shop__gallery');
if (galleryMain && galleryLp2) {
  if (window.innerWidth < 1023) {
    galleryLp2.appendChild(galleryMain);
    }
}

var galleryLp3 = document.querySelector('.hoox-shop__gallery-lp-3');
if (galleryMain && galleryLp3) {
  if (window.innerWidth < 1023) {
    galleryLp3.appendChild(galleryMain);
    }
}

